import {useEffect} from "react";


const tg = window?.Telegram?.WebApp;

export function useTelegram(isBack?:  string) {

  useEffect(() => {
    if (!isBack) return;
    // tg.BackButton.show();
    // return () => {
    //   tg.BackButton.hide();
    // }
  }, []);

  const onToggleButton = () => {
    if (!tg) return;
    if (tg.MainButton.isVisible) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  }

  const onCloseTelegram = () => {
    if (!tg) return;
    tg.close()
  }

  return {
    tg,
    user: tg?.initDataUnsafe?.user,
    onCloseTelegram,
    onToggleButton,
    tgQueryId: tg?.initDataUnsafe?.query_id,
  }
}