import {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  visible?: boolean;
}

const RegistrationCoins: FC<Props> = ({visible = true}) => {
  const {t} = useTranslation();

  if (!visible) return null;
  return (
    <div className='registration-coins'>
      <div className="registration-coins-gift" />
      <div className="registration-coins-text text-bold-12 pre-wrap">
        {t('REG_COINS_TEXT')}
      </div>
      <div className="registration-coins-icon">
        <span>💎</span>
        <span>💎</span>
        <span>💎</span>
      </div>
    </div>
  );
}

export default RegistrationCoins;