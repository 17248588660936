import React, {FC, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {AuthRecovery, AuthSign} from "./index";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {HeaderSecondary} from "../../containers/Header";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import AppStore from "../../store/AppStore";
import {Spinner} from "../../components/Loadable";

interface Props {
}

const dataTitles: any = {
  'login': 'SIGN_IN',
  'register': 'CREATE_ACC',
  'recovery': 'RESET_PASSWORD',
}

type TType = 'login'|'register'|'recovery'

const AuthPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [prevState, setPrevState] = useState<{
    prevPathname?: string,
    sharedChatId?: string,
    modelIdConversation?: string
  }>();

  useEffect(() => {
    if (AppStore.ready || AppStore.user?.id) {
      navigate('/', {replace: true});
    }
    setPrevState(location.state || {});
  }, [AppStore.ready, AppStore.user?.id]);

  useEffect(() => {
    if (searchParams.get('event') === 'password_updated') {
      toast.success('Password updated');
      searchParams.delete('event')
    }
    if (searchParams.get('email')) {
      searchParams.delete('email');
    }
    setSearchParams(searchParams)
  }, []);

  const authType = params['*'] || 'login';

  const handleBack = (conversationId?: number|boolean) => {
    if (typeof conversationId === 'number') {
      navigate(`/chat/${conversationId}`, {replace: true});
    } else {
      navigate(prevState?.prevPathname || '/', {replace: true});
    }
  }

  const handleChangeType = (type: TType) => {
    navigate(`/auth/${type}`, {replace: true});
  }

  if (!AppStore.ready || AppStore.user?.id) return null;

  return (
    <div className='container auth-page'>
      <HeaderSecondary onClick={() => navigate('/')} back title={dataTitles[authType]}/>
      <div className='auth-page-content'>
        <div className='d-flex justify-content-center'>
          <div className="tabs mb-3 mb-md-4 text-nowrap">
            <div
              className={`tab${authType === 'login' ? ' active' : ''}`}
              onClick={() => handleChangeType('login')}
            >
              <span className='text-truncate'>{t('SIGN_IN')}</span>
            </div>
            <div
              className={`tab${authType === 'register' ? ' active' : ''}`}
              onClick={() => handleChangeType('register')}
            >
              <span className='text-truncate'>{t('SIGN_UP')}</span>
            </div>
          </div>
        </div>
        {authType === 'recovery'
          ?
          <AuthRecovery onTypeChange={() => navigate('/auth/register', {replace: true})}/>
          :
          <GoogleOAuthProvider
            clientId='709075734698-c1s86vjvosjre4ag8q46b0o6nk8g33h7.apps.googleusercontent.com'>
            <AuthSign
              sharedChatId={prevState?.sharedChatId}
              modelIdConversation={prevState?.modelIdConversation}
              authType={authType as TType}
              onTypeChange={(type) => handleChangeType(type)}
              onFinish={handleBack}
            />
          </GoogleOAuthProvider>
        }
      </div>
    </div>
  );
})

export default AuthPage;